import React from 'react'
import {
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  IconButton,
  styled,
  CircularProgress
} from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import EditIcon from '@mui/icons-material/Edit'
import moment from 'moment'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

const StyledTable = styled(Table)(() => ({
  whiteSpace: 'nowrap',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } }
  }
}))

const SrNoTableCell = styled(TableCell)(() => ({
  width: '50px',
  textAlign: 'left'
}))

const CustomersTable = ({
  loading,
  page,
  rowsPerPage,
  allCustomers,
  hasPermission,
  totalCustomers,
  handleViewClick,
  handleEdit,
  handleChangePage,
  handleChangeRowsPerPage,
  handleClickOpen
}) => {
  return (
    <Box width="100%" overflow="auto" mt="20px" mb="-10px">
      <StyledTable>
        <TableHead>
          <TableRow colSpan={12}>
            <SrNoTableCell
              align="left"
              sx={{ width: { xs: '90px', sm: '90px', md: '90px' } }}
            >
              No.
            </SrNoTableCell>

            <TableCell
              align="center"
              sx={{ width: { xs: '170px', sm: '170px', md: '200px' } }}
            >
              Action
            </TableCell>
            <TableCell
              align="left"
              sx={{ width: { xs: '140px', sm: '140px', md: '250px' } }}
            >
              Customer Name
            </TableCell>
            <TableCell
              align="center"
              sx={{ width: { xs: '100px', sm: '100px', md: '170px' } }}
            >
              Date{' '}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                width: { xs: '110px', sm: '110px', md: '250px' }
              }}
            >
              Added By
            </TableCell>

            <TableCell
              align="center"
              sx={{ width: { xs: '160px', sm: '160px', md: '240px' } }}
            >
              User
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={12} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : allCustomers.length > 0 ? (
            allCustomers.map((customer, index) => (
              <TableRow key={customer?._id}>
                <SrNoTableCell>{page * rowsPerPage + index + 1}</SrNoTableCell>
                <TableCell align="center">
                  {hasPermission('Customers:view') && (
                    <IconButton
                      onClick={() => handleViewClick(customer)}
                      color="primary"
                      aria-label={`view ${customer.customerName}`}
                    >
                      <RemoveRedEyeIcon />
                    </IconButton>
                  )}
                  {hasPermission('Customers:edit') && (
                    <IconButton
                      onClick={() =>
                        handleEdit(
                          customer,
                          customer?.leadId?._id,
                          customer?.leadId?.customerName
                        )
                      }
                      color="primary"
                      aria-label={`edit ${customer?.customerName}`}
                    >
                      <EditIcon />
                    </IconButton>
                  )}

                  {hasPermission('Customers:add') && customer?.leadId?._id && (
                    <IconButton
                      onClick={() =>
                        handleClickOpen(
                          customer?.leadId?._id,
                          customer?.leadId?.customerName
                        )
                      }
                      sx={{ color: 'green' }}
                      aria-label={`lead_sale_add ${customer?.customerName}`}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell align="left">
                  {customer?.leadId?.customerName}
                </TableCell>
                <TableCell align="center">
                  {moment(customer?.createdAt).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell align="center">{customer?.addedBy}</TableCell>
                <TableCell align="center">{customer?.user?.name}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={12} align="center">
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        component="div"
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        count={totalCustomers}
        rowsPerPageOptions={[10, 20, 30, 50, 100]}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Box>
  )
}

export default CustomersTable
