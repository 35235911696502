// src/SocketContext.js
import useAuth from 'app/hooks/useAuth'
import axios from 'axios'
import axiosInstance from 'axiosInstance'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useReducer } from 'react'
import { io } from 'socket.io-client'

// Create a context for the socket
const SocketContext = createContext()

// Set up the socket connection
const socket = io(
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:9001',
  {
    transports: ['websocket']
  }
)

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_NOTIFICATIONS': {
      return { ...state, notifications: action.payload }
    }

    case 'DELETE_NOTIFICATION': {
      return { ...state, notifications: action.payload }
    }

    case 'CLEAR_NOTIFICATIONS': {
      return { ...state, notifications: action.payload }
    }

    default:
      return state
  }
}

// Create a provider component
export const SocketProvider = ({ children }) => {
  const [leadsOverview, setLeadsOverview] = useState([])
  const [transferLeadNotification, setTransferLeadNotification] = useState(null)
  const [state, dispatch] = useReducer(reducer, [])
  const { user } = useAuth()

  const deleteNotification = async (notificationID) => {
    try {
      const res = await axios.post('/api/notification/delete', {
        id: notificationID
      })
      dispatch({ type: 'DELETE_NOTIFICATION', payload: res.data })
    } catch (e) {
      console.error(e)
    }
  }

  const clearNotifications = async () => {
    try {
      const res = await axios.post('/api/notification/delete-all')
      dispatch({ type: 'CLEAR_NOTIFICATIONS', payload: res.data })
    } catch (e) {
      console.error(e)
    }
  }

  const getNotifications = async () => {
    try {
      const res = await axiosInstance.get(
        '/api/announcements/weekly-notification'
      )

      dispatch({ type: 'LOAD_NOTIFICATIONS', payload: res.data.notifications })
    } catch (e) {
      console.error(e)
    }
  }

  const createNotification = async (notifications) => {
    try {
      // const res = await axios.post('/api/notification/add', { notification })

      // dispatch({ type: 'CREATE_NOTIFICATION', payload: res.data })
      dispatch({
        type: 'LOAD_NOTIFICATIONS',
        payload: [...state.notifications, notifications]
      })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    getNotifications()
  }, [])

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      socket.disconnect()
    }
  }, [])

  // Listen for socket events related to different charts
  useEffect(() => {
    // Emit the user's role to the server on connection

    if (user?.id) {
      socket.emit('user_connected', { userId: user?.id, role: user?.role })
    }

    // Listen for leads overview data updates
    socket.on('leads_overview', (data) => {
      setLeadsOverview(data)
    })

    socket.on('transfer_lead', (data) => {
      createNotification(data)
      setTransferLeadNotification(data)
    })

    // Cleanup listeners on component unmount
    return () => {
      socket.off('leads_overview')
      socket.off('transfer_lead')
    }
  }, [socket, user])

  return (
    <SocketContext.Provider
      value={{
        socket,
        leadsOverview,
        transferLeadNotification,
        setTransferLeadNotification,
        getNotifications,
        // deleteNotification,
        clearNotifications,
        createNotification,
        notifications: state.notifications
      }}
    >
      {children}
    </SocketContext.Provider>
  )
}

// Custom hook to use the socket in other components
export const useSocket = () => useContext(SocketContext)
